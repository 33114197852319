import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowDownIcon } from '@heroicons/react/solid';
import PhotoGallery from '../components/PhotoGallery';
import ContactUs from '../components/ContactUs';
import Reviews from '../components/Reviews';
import Special from '../components/Special';
import SEO from '../components/SEO';

export default function IndexPage({ path }) {
  const data = useStaticQuery(graphql`
  query {
    splash: file(relativePath: { eq: "yyd-splash.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: NORTH }, )
      }
    }
    gallery: sanityGallery(name: {eq: "Homepage Gallery"}) {
      photos {
        id
        image {
          asset {
            gatsbyImageData( placeholder: BLURRED)
          }
        }
        name
        order
        tags {
          title
        }
        caption
      }
      name
      caption
    }
  }
`);
  return (
    <>
      <SEO />
      <section className="relative z-20 shadow-lg">
        <div className="mx-auto">
          <div className="relative shadow-xl sm:overflow-hidden md:py-16 xl:py-48">
            <div className="absolute inset-0">
              <GatsbyImage
                className="w-full h-full"
                imgClassName="object-top"
                image={data.splash.childImageSharp.gatsbyImageData}
                alt="Yuan Yen Do students with trophies"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-gray-600 to-blue-900 mix-blend-multiply" />
            </div>
            <div className="relative px-2 py-16 sm:px-6 sm:pb-24 md:-mt-16 lg:px-8 xl:-mt-32">
              <h1 className="text-4xl font-extrabold tracking-tight text-center md:space-y-3 lg:text-6xl filter drop-shadow-lg ">
                <span className="block font-bold text-white">
                  Build Self
                  {' '}
                  <span className="block font-black text-transparent sm:inline bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">Confidence</span>
                </span>
                <span className="block text-2xl italic font-normal text-white sm:text-3xl lg:text-5xl">inside and outside</span>
                <span className="block font-bold text-white">
                  the
                  {' '}
                  <span className="font-black text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600 sm:pb-5">Dojo</span>
                </span>
              </h1>
              <div className="flex justify-center max-w-sm mx-auto mt-10 sm:max-w-none">
                <AnchorLink
                  to="/#introductory-special"
                  title="Introductory Special"
                  className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
                >
                  <ArrowDownIcon className="w-5 h-5 pr-2" />
                  Get Started
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Special path={path} />
      <PhotoGallery gallery={data.gallery} />
      <ContactUs />
      <Reviews />
    </>
  );
}
